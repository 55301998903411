<!-- 订单确认 -->
<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>
        <div class="order-confirm v2-g-w1200">
            <div class="customer-sel form  padding15 v2-g-flex-row v2-g-flex-space-between" v-if="userIdentity == '2'">
                <div><label>单位名称</label><input type="text" v-model="userInfo.name" placeholder="点击查询客户"
                        @focus="showCustomerDlg = true" /></div>
                <div><label>单位编码</label><input type="text" v-model="userInfo.num" disabled placeholder="自动识别" /></div>
                <div><label>单位地址</label><input type="text" disabled v-model="userInfo.address" placeholder="自动识别" />
                </div>
            </div>
            <div class="addr padding15">
                <div class="block-title addr-title v2-g-flex-row v2-g-flex-space-between">
                    <p>选择收货地址</p>
                    <!--<p class="link-color" v-if="userIdentity == '1'" @click="jumpTo('v2-address')"><i-->
                    <!--        class="iconfont icon-edit-1"></i>管理收货地址</p>-->
                </div>
                <div class="addr-wrapper" ref="addrWrapper">
                    <!--<div v-if="lstAddress.length == 0" class="no-addr">-->
                    <!--    <div v-if="userIdentity == '1'" class="no-div" @click="jumpTo('v2-address')">-->
                    <!--        <div class="icon"><i class="iconfont icon-add-circle"></i></div>-->
                    <!--        <div v-if="userIdentity == '1'" @click="jumpTo('v2-address')">添加收货地址</div>-->
                    <!--        <div v-if="userIdentity == '2'">暂无收货地址</div>-->
                    <!--    </div>-->
                    <!--</div>-->
                    <div class="addr-content" v-for="item of lstAddress" :key="item.id">
                        <span class="border-sel" :class="item.id == addressId ? 'sel' : ''" @click="setAddr(item)">
                            <span>{{ item.consignee }}</span>
                            <span class="ddd">{{item.addreErpDetail }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="order">
                <div class="block-title order-title padding15 v2-g-flex-row v2-g-flex-space-between">
                    <div style="display: flex; align-items: center;">
                      <p style="margin: 0;">确认订单</p>
                      <span class="order-title_tips" v-if="order && order.onlinePayVO && order.onlinePayVO.tips">{{ order.onlinePayVO.tips }}</span>
                    </div>
                    <p @click="showOrderList = !showOrderList" class="link-color">{{ showOrderList ? '图文展示': '列表展示' }}</p>
                </div>
                <div v-if="!showOrderList">
                    <div class="h v2-g-flex-row v2-g-flex-space-between v2-g-align-center">
                        <div class="columns-product">商品</div>
                        <div class="columns-price">单价</div>
                        <div class="columns-num">数量</div>
                        <div class="columns-subtotal">小计</div>
                    </div>
                    <div class="c">
                        <div class="productbox v2-g-flex-row v2-g-flex-space-between v2-g-align-center"
                             style="display: block;"
                            v-for="item of order.orderLineVoList">
                            <div style="display: flex; align-items: center;">
                              <div class="product-info v2-g-flex-row">
                                <div class="product-img">
                                  <img :src="item.productPhoto" alt />
                                </div>
                                <!-- 商品描述 -->
                                <div class="product-introduce">
                                  <p>【{{ item.specifications }}】{{ item.productName }}</p>
                                  <p>{{ item.produceFactory }}</p>
                                  <p v-if="item.nearEffectivePeriod">
                                    效期 {{ item.nearEffectivePeriod | formatDate }}
                                  </p>
                                  <p v-else>效期</p>
                                  <div class="special-offer" v-if="item.editor">
                                    <p>活动</p>
                                    <p>{{ item.editor }}</p>
                                  </div>
                                </div>
                              </div>

                              <div class="product-price">
                                <span v-if="item.specialPriceSplittingInfoVoList.length">均价 ￥{{ item.averagePrice }}</span>
                                <span v-else>原价 ￥{{ item.preferentialPrice }}</span>
                              </div>

                              <div class="product-num">
                                <div class="special-offer-content" v-if="item.specialPriceSplittingInfoVoList.length">
                                  <template v-for="(good, index) in item.specialPriceSplittingInfoVoList">
                                    <div class="special-offer-item" :key="index">
                                      <span class="special-offer-item-left" v-if="good.type == 1">特价￥{{ good.price }}</span>
                                      <span class="special-offer-item-left" v-if="good.type == 2">特价￥{{ good.price }}</span>
                                      <span class="special-offer-item-left" v-if="good.type == 3">原价￥{{ good.price }}</span>
                                      <span class="special-offer-item-right">×{{ good.num }}{{ item.sellUnit }}</span>
                                    </div>
                                  </template>
                                </div>

                                <div v-else style="padding-right: 16px">
                                  <span>×{{ item.totalNumber }}</span>
                                  <template v-if="item.tipsStr">
                                    <span style="margin-left: 4px">中包装</span>
                                    <div class="num">(实际购买{{ item.tipsStr }}{{ item.sellUnit }})</div>
                                  </template>
                                </div>
                              </div>

                              <div class="product-money">
                                <p>¥{{ item.subtotalMoney }}</p>
                                <p>优惠 ¥{{ item.preferentialTotalMoney }}</p>
                              </div>
                            </div>

                          <!--赠品信息-->
                          <template v-if="item.gift">
                            <div style="display: flex; align-items: center;">
                              <div class="product-info v2-g-flex-row">
                                <div class="product-img product-info_img">
                                  <img style="width: 70px; height: 70px;" :src="item.gift.picPath" alt />
                                  <div class="product-info_giveaway">赠品</div>
                                </div>
                                <!-- 商品描述 -->
                                <div class="product-introduce">
                                  <p>{{ item.gift.productName }}【{{ item.gift.specifications }}】</p>
                                  <p>{{ item.gift.produceFactory }}</p>
                                </div>
                              </div>

                              <div class="product-price" style="color: #E5432E; font-weight: normal;">￥{{ item.gift.unitPrice }}</div>

                              <div class="product-num" style="flex: 0 0 27%;">{{ item.gift.totalNumber }}</div>

                              <div class="product-price" style="color: #E5432E; font-weight: normal;">￥{{ item.gift.subtotalMoney }}</div>
                            </div>
                          </template>
                        </div>
                    </div>
                </div>

                <div v-else class="list-show">
                  <el-table
                    :data="order.orderLineVoList"
                    style="width: 100%; margin-bottom: 20px;"
                    row-key="productId"
                    border
                    stripe
                    size="mini"
                    default-expand-all
                    :header-cell-style="{ background:'#f5f5f5', color: '#636363' }"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="productName" label="商品名称" />
                    <el-table-column prop="specifications" label="规格" />
                    <el-table-column prop="produceFactory" label="生产厂家" width="250" />
                    <el-table-column prop="nearEffectivePeriod" label="效期" />
                    <el-table-column prop="preferentialPrice" label="单价（元）" />
                    <el-table-column prop="sellUnit" label="包装" />
                    <el-table-column prop="totalNumber" label="数量" />
                    <el-table-column prop="preferentialTotalMoney" label="优惠（元）" />
                  </el-table>
                </div>
            </div>
            <div class="ticket padding15 v2-g-flex-row  v2-g-align-center">
                <p class="h2 ">是否需要电话开票</p>
                <div class="tc v2-g-flex-row">
                    <span class="ck  link-color" :class="isgivePhone ? 'sel' : ''">
                        <CheckBox :isCheck="isgivePhone" v-on:click="isgivePhone = true">是</CheckBox>
                    </span>
                    <span class="ck link-color" :class="!isgivePhone ? 'sel' : ''">
                        <CheckBox :isCheck="!isgivePhone" v-on:click="isgivePhone = false">否</CheckBox>
                    </span>
                </div>

            </div>
            <div class="bal padding15" v-if="(actBal.totalBalanceAmount > 0)">
                <div class="block-title">
                    <p>余额抵扣</p>
                </div>

                <div class="bal-c v2-g-flex-row  v2-g-align-center">
                    <input
                      style="width: 20px;height: 20px"
                      type="checkbox"
                      name="chbox"
                      value="disabled"
                      :disabled="HBdisabled"
                      v-model="isUseBalance"
                      v-on:click="checkBalanceBox"
                    />
                    <label class="red" style="margin-left: 6px;">最高可使用红包{{order.balanceAmount }}元</label>
                    <span style="margin-left: 6px;">
                        账户余额 {{ actBal.totalBalanceAmount || 0 }} 元。
                        <label v-if="(order.redPacket > 0)">本次订单预计赠送红包
                            <label class="red">{{order.redPacket }}个</label>
                            （以实际发货数量为准，每个红包0.5元）
                            <img src="@/assets-v2/images/redbag.png" style="width:30px;vertical-align: middle;" />
                            <font style="color: rgb(244, 87, 87);font-size: 1.2rem; font-weight: 600;">
                                x{{ order.redPacket }}个</font>
                        </label>
                    </span>
                </div>
            </div>

            <div class="model-box-ball padding15">
                <div class="block-title">
                    <p>优惠券</p>
                </div>
                <div class="balance-list-box" style="flex-flow: wrap">
                    <template v-if="checkCouponStatus">
                        <div v-if="checkCouponStatus == '1'">
                            <CheckBox :isCheck="isUseCoupon" v-on:click="isCouponChange">
                                <label style="font-size: 1rem;color: red;">立即使用优惠券:{{order.couponCurrentDeductionPrice }}元</label>
                            </CheckBox>

                        </div>
                        <div v-if="checkCouponStatus == '1' && order.couponCurrentDeductionPrice == 5">
                            （再添加{{ order.couponNeedAddPrice }}元品种，本次可抵扣{{ order.couponNextDeductionPrice }}元）</div>
                        <div class="balance1" style="width: 100%" v-if="checkCouponStatus == '0'">
                            订单未满足99，当前（{{ order.couponCurrentDeductionPrice }}元）优惠券不可用</div>
                    </template>
                    <template v-else>
                        <div class="balance1" style="width: 100%">无优惠券可用</div>
                    </template>
                </div>
            </div>

<!--            <div class="payway padding15">-->
<!--                <div class="block-title">-->
<!--                    <p>选择支付方式<span class="tip" v-if="(getPayActTip().length > 0)">{{ getPayActTip() }}</span></p>-->
<!--                </div>-->
<!--                <div class="c">-->
<!--                    <span class="border-sel " @click="clickPayType(item.payType)"-->
<!--                        :class="item.payType == payKey ? 'sel' : ''" v-for="item of lstPayType">{{item.payTypeName }}</span>-->
<!--                </div>-->
<!--                <div class="maxlimitAmt_box" v-if="order.maxlimitAmt != null && order.maxlimitAmt != ''">-->
<!--                    <span>!</span>-->
<!--                    <label>{{ order.maxlimitAmt }}</label>-->
<!--                </div>-->
<!--            </div>-->
            <div class="padding15 delivery v2-g-flex-row v2-g-align-center">
                <p class="h2">选择配送方式
                    <span v-if="giveKey === '2' || giveKey === '3'"> 线下结算 </span>
                </p>
                <div style="margin-left: 40px;">
                    <span @click="clickGiveType(item.key)" class="border-sel " v-for="(item) in lstWay"
                        :class="item.key == giveKey ? 'sel' : ''">{{item.value }}</span>
                </div>
            </div>
            <div class="invoice padding15">
                <div class="block-title v2-g-flex-row v2-g-align-center">
                    <CheckBox :isCheck="isInvoice" v-on:click="setInvoice"></CheckBox>
                    <p class="h2">开具发票（可补开）</p>
                    <p class="link-color" v-if="userIdentity == '1'" @click="jumpTo('v2-invoicetop')"><i
                            class="iconfont icon-edit-1"></i>管理发票</p>
                </div>

                <div class="form v2-g-flex-row v2-g-align-center v2-g-flex-space-between">
                    <div>
                        <label>发票抬头</label>
                        <select v-model="invoiceForm.invoiceTitle" :disabled="!isInvoice" placeholder="请选择" expanded
                            @change="getInvoiceTitle">
                            <option :value="item.id" :key="index" v-for="(item, index) in lstticketTitle">
                                {{ item.title }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label style="width:100px">纳税人识别号</label>
                        <input type="text" :disabled="!isInvoice" v-model="invoiceForm.num" placeholder="自动识别"
                            disabled />
                    </div>
                    <div>
                        <label>开票方式</label>
                        <span><input type="radio" :disabled="!isInvoice" name="name" v-model="invoiceForm.invoiceType"
                                value="1" />电子发票</span>
                        <span><input type="radio" :disabled="!isInvoice" name="name" v-model="invoiceForm.invoiceType"
                                value="2" />纸值发票</span>
                    </div>
                </div>
                <div class="remark form v2-g-flex-row v2-g-align-center">
                    <label>备注</label>
                    <input :disabled="!isInvoice" type="text" v-model="invoiceForm.remarks" />
                </div>
            </div>
            <div class="sum padding15">
                <ul>
                    <li style="display: flex; justify-content: right; align-items: center;">
                      <div style="display: flex; flex-direction: column;">
                        <label v-if="order.orderLineVoList">{{ order.orderLineVoList.length }}件商品</label>
                        <label style="color: #E5432E;" v-if="order.giftOrderNum">({{ order.giftOrderNum }}件赠品)</label>
                      </div>
                      <span>¥ {{ order.sumMoney }}</span>
                    </li>
                    <li><label>特价立减</label> <span>-¥ {{ order.ticketDiscount }}</span></li>
                    <li v-if="order.activityNextInfoVO">
                      <label>整单立减</label>
                      <span>-¥ {{ order.activityNextInfoVO.type == 0 ? 0 : order.activityNextInfoVO.reductionMoney }}</span>
                    </li>
                    <li v-if="!order.piaoZhe3TrueOrFalse"><label>现金返点</label> <span>-¥ {{ order.cashAmount }}</span></li>
                    <li><label>余额抵扣</label> <span>-¥ {{ isUseBalance? order.balanceAmount : 0 }}</span></li>
                    <li><label>优惠卷抵扣</label> <span>-¥ {{isUseCoupon?(order.couponCurrentDeductionPrice || 0) : 0 }}</span></li>
                    <li><label>运费</label> <span>¥ {{ (order.freight || 0) }}</span></li>
                    <li><label>线上支付优惠</label> <span>-¥ {{ order.onlinePaymentInstantDiscount }}</span></li>
                    <li><label>费用合计</label> <span style="color:red">¥ {{ order.totalMoney }}</span></li>
                </ul>
            </div>
            <div class="sum padding15" style="padding: 0 15px">
              <ul>
                <li v-if="order.activityPriceFor99_5 && order.activityPriceFor99_5 > 0">
                  <label>满99返5</label><span>¥ {{ order.activityPriceFor99_5 }}</span>
                </li>
                <li v-if="order.cartOrderReturnProportionVO && order.cartOrderReturnProportionVO.isShow === 1">
                  <label style="width: 200px;">299凑单返利</label>
                  <span>¥ {{order.giftAmount }}</span>
                </li>
              </ul>
            </div>
            <div class="btn padding15">
                <button type="button" @click="toPay" :disabled="disSubmit">提交订单</button>
            </div>
        </div>

        <Dialog :show="showCustomerDlg" title="选择客户" width="60%" height="70%" @close="showCustomerDlg = false">
            <div class="customer-list">
                <div class="customer-input form v2-g-flex-row  v2-g-align-center v2-g-flex-space-between">
                    <input type="text" placeholder="输入客户编码或者名称查询" v-model="userName" />
                    <button type="button" @click="getCustomerList">查询</button>
                </div>
                <div class="customer-content">
                    <ul>
                        <li class="h v2-g-flex-row  v2-g-align-center v2-g-flex-space-between">
                            <p class="code">客户编码</p>
                            <p class="name">客户名称</p>
                            <p class="addr">联系人</p>
                        </li>
                        <li v-for="item of userList" class="v2-g-flex-row  v2-g-align-center v2-g-flex-space-between"
                            @click="setUserInfo(item)">
                            <p class="code">
                                <CheckBox :isCheck="(item.companyId == userContent.companyId)"
                                    @click="setUserInfo(item)">
                                    {{ item.account }}</CheckBox>
                            </p>
                            <p class="name">{{ item.name }}</p>
                            <p class="addr">{{ item.contactsName }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div slot="footer">
                <button type="button" @click="sureSelect">确认</button>
                <button type="button" @click="(showCustomerDlg = false)">取消</button>
            </div>
        </Dialog>

        <!-- 创建订单失败提示 -->
        <Dialog :show="showCreateOrderFail" :hideClose=true title="温馨提示" width="500px" height="681px"
            @close="showCreateOrderFail = false">
            <div class="errorMsg">
                <h3 class="errorMsg-title">尊敬的普健用户您好：</h3>
                <div class="errorMsg-content">
                    {{
                        errorData.isCondition == 1 ? "由于价格或库存变动，当前订单金额已不满足299元，请点击下方“重新选购”按钮返回购物车。" :
                            "由于市场价格与库存实时波动，已为您同步最新价格和库存。"
                    }}
                </div>
                <div class="errorMsg-list">
                    <div class="errorMsg-list-box">
                        <div class="errorMsg-list-box-item v2-g-flex-row  v2-g-align-center v2-g-flex-space-between">
                            <div class="item-width">商品名称</div>
                            <div class="item-width">原下单数</div>
                            <div class="item-width">商品库存</div>
                            <div class="item-width">原单价</div>
                            <div class="item-width">现单价</div>
                        </div>
                        <div class="errorMsg-list-box-item box-item-list v2-g-flex-row  v2-g-align-center v2-g-flex-space-between"
                            v-for="(item, eindex) in errorData.abnormalOrderForms" :key="eindex">
                            <div class="item-width item-width-bg">{{ item.productName }}</div>
                            <div class="item-width item-width-bg">{{ item.oldqty }}</div>
                            <div class="item-width item-width-bg" v-if="item.lx != 1">{{ item.newqty }}</div>
                            <div class="item-width item-width-bg" v-if="item.lx != 1">¥{{ item.oldprice }}</div>
                            <div class="item-width item-width-bg" v-if="item.lx != 1">¥{{ item.newprice }}</div>
                            <div style="width: 60%;text-align: center;font-size: 14px;background-color: #ffffff;"
                                v-if="item.lx == 1">该商品低于进货价，无法售卖</div>
                        </div>
                    </div>
                </div>
                <div class="errorMsg-bottom">
                    <div class="errorMsg-price">
                        <p>红包抵扣</p>
                        <div class="errorMsg-price-item">
                            <div class="errorMsg-price-item-le">
                                <span>原单可抵扣：¥{{ errorData.oldBalanceAmount }}</span>
                            </div>
                            <div class="errorMsg-price-item-le">
                                <span>现单可抵扣：¥{{ errorData.balanceAmount }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="errorMsg-price">
                        <p>满减优惠</p>
                        <div class="errorMsg-price-item">
                            <div class="errorMsg-price-item-le">
                                <span>原单优惠：¥{{ errorData.oldTotalReductionAmount }}</span>
                            </div>
                            <div class="errorMsg-price-item-le">
                                <span>现单优惠：¥{{ errorData.totalReductionAmount }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="errorMsg-price">
                        <p>原订单金额：¥{{ errorData.oldRealPayAmount }}</p>
                        <div class="errorMsg-price-item">
                            <div class="errorMsg-price-item-le">
                                <span class="nowPrice">现订单金额：<span style="color: #FF2972;">¥{{errorData.realPayAmount }}</span></span>
                            </div>
                            <div class="errorMsg-price-item-le" v-if="errorData.cashRebate >= 0 && !order.piaoZhe3TrueOrFalse">
                                <span>现金返点：¥{{ errorData.cashRebate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="errorMsg-btns">
                        <button type="button" class="button round danger" rounded
                            @click="$router.push({ name: 'v2-shopcard' })">重新选购</button>
                        <button type="button" class="button round info" rounded v-if="errorData.isCondition == 0"
                            @click="enterOrder(errorData.orderNumber)">确认订单</button>
                        <button type="button" rounded v-if="errorData.isCondition == 1" :disabled="true">确认订单</button>
                    </div>
                </div>
            </div>
        </Dialog>

        <!-- 创建订单异常 -->
        <Dialog :show="showCreateOrderError" title="温馨提示" width="530px" height="240px"
            @close="showCreateOrderError = false">
            <div class="errorMsg">
                <h3 class="errorMsg-title">尊敬的普健用户您好：</h3>
                <div class="errorMsg-content">
                    {{ orderErrorMsg }}
                </div>
                <div class="errorMsg-enter">
                    <button type="button" class="button info" @click="(showCreateOrderError = false)">确定</button>
                </div>
            </div>
        </Dialog>

        <!-- 欠款提示 -->
        <Dialog :show="showDebt" :title="arrearsData.debitFlag" width="400px" height="300px" @close="showDebt = false">
            <div class="arrears-dialog" style="border-radius: 10px">
                <div class="proBox1">
                    <div class="proBox-msg" v-if="arrearsData.currentSumDebit > 0">
                        <p>截止当前，<span style="color: #E5432E">您已累计欠款{{ arrearsData.currentSumDebit }}元，</span></p>
                        <p>为不影响您正常下单，请立即还款。</p>
                    </div>
                    <div class="proBox-msg" v-if="arrearsData.currentSumDebit == 0">
                        <p>请联系业务员调整授信额度。</p>
                    </div>
                    <div class="proBox-btn" v-if="arrearsData.currentSumDebit > 0" @click="nowArrears">
                        <button type="button" @click="nowArrears">立即还款</button>
                    </div>
                </div>
            </div>
        </Dialog>

        <!-- 订单进度条 -->
        <Dialog :show="showProcess" title="创建订单" width="50%" height="50%" @close="showProcess = false">
            <div class="inner-dialog inner-dialog-balance" style="border-radius: 10px">
                <div class="proBox">
                    <div class="proBox-msg">
                        订单正在创建中......
                    </div>
                    <b-progress type="is-success" :value="orderPercent" show-value format="percent"></b-progress>
                    <div class="proBox-msg-des">
                        <p>由于商品售卖较为火爆，</p>
                        <p>正在为您校验商品库存，请稍等....</p>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<style scoped lang="less">
.padding15 {
    padding: 15px 15px;
    border-bottom: 1px solid #EDEDED;
}

.block-title {
    height: 30px;
    margin-bottom: 20px;
    font-size: 1.125rem;
    font-weight: 500;
    text-align: left;
    line-height: 30px;


}

.button {
    &.info {
        background-color: #167df0;
        border-color: transparent;
        color: #fff;
    }
}

.errorMsg-title {
    font-size: 1rem;
}

.errorMsg-content {
    font-size: 1rem;
    margin: 10px;
}

.errorMsg-enter {
    text-align: center;
}

.button {
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;

    &.round {
        border-radius: 9999px;
        padding-left: 1.25em;
        padding-right: 1.25em;
    }

    &.danger {
        background-color: #f14668;
        border-color: transparent;
        color: #fff;
    }

    &.info {
        background-color: #167df0;
        border-color: transparent;
        color: #fff;
    }
}

.h2 {
    font-size: 1.125rem;
    font-weight: 500;
    text-align: left;

}

.border-sel {
    padding: 2px 15px;
    border: 1px solid #EDEDED;
    cursor: pointer;

    &.sel,
    &:hover {
        border: 1px solid #FA8E36;

        position: relative;

        &:after {
            content: "";
            background: url(../../assets-v2/images/js_gouxuan.png) no-repeat;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 15px;
            display: block;
        }
    }
}

.form {
    label {
        width: 80px;
        display: inline-block;
        text-align: right;
        margin-right: 10px;
    }

    input[type='text'],
    select {
        height: 30px;
        width: 200px;
        padding-left: 6px;
        border: 1px solid #EDEDED;
        border-radius: 5px;
    }

    input[type='radio'] {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-left: 20px;
        margin-right: 5px;
    }
}

.order-confirm {
    border: 1px solid #EDEDED;
    font-size: 1.3rem;

    .addr {
        .addr-title {
            p {
                &:nth-child(2) {
                    color: #3875F6;
                }
            }
        }

        .addr-wrapper {
            .no-addr {
                .no-div {
                    width: 100px;
                    height: 100%;
                    margin: 0 auto;
                    color: #999;
                    cursor: pointer;

                    .icon {
                        i {
                            font-size: 3rem;
                        }
                    }
                }
            }

            transition: all 0.2s ease-out;

            .addr-content {
                text-align: left;
                height: 30px;
                line-height: 30px;
                margin: 10px 0;
                cursor: pointer;

                .ddd {
                    margin: 0 10px;
                }

                .addr-btn {
                    border-radius: 4px;
                    color: #fff;
                    font-size: 0.7rem;
                    padding: 2px 5px;
                    background: #6ad5ff;

                    &.default {
                        background: #FA8E36;
                    }
                }
            }
        }


        .more {
            margin: 10px 0;
            text-align: left;
            font-size: 0.875rem;

            i {
                vertical-align: middle;
            }
        }
    }

    .order {
        .order-title {
            margin-bottom: 0;
            height: 55px;
            align-items: center;

            p {
                font-weight: 500;

                &:nth-child(1) {
                    margin-top: 10px;
                    font-size: 1.125rem;
                    font-family: Source Han Sans CN;
                }

                &:nth-child(2) {
                    color: #3875F6;
                    font-size: 1rem;
                }
            }

          .order-title_tips {
            position: relative;
            border-radius: 5px;
            color: #ffffff;
            font-weight: normal;
            font-size: 0.875rem;
            padding: 0 4px 0 10px;
            line-height: 26px;
            background-color: #ff5151;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
            margin-left: 30px;

            &:before {
              content: "";
              position: absolute;
              top: 50%;
              right: 100%;
              left: auto;
              border-top: 5px solid transparent;
              border-right: 5px solid #f45757;
              border-bottom: 5px solid transparent;
              z-index: 38;
              transform: translateY(-50%);
            }
          }
        }

        .h {
            height: 48px;
            background: #F8F8F8;

            .columns-product {
                width: 416px;
            }

            .columns-num,
            .columns-price,
            .columns-subtotal{
                flex: 1;
            }
        }

        .c {
            .productbox {
                padding: 20px;
                border-bottom: 1px solid #f2effb;

                .product-info {
                    width: 410px;

                    .product-img {
                        width: 120px;
                        height: 120px;

                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .product-introduce {
                        text-align: left;

                        p {
                            color: #666666;
                            font-size: 0.75rem;
                            margin: 10px 0;

                            &:nth-child(1) {
                                color: #222222;
                                font-size: 0.875rem;
                                font-weight: 400;
                            }
                        }
                    }
                }

                .product-price {
                  flex: 1;
                    font-size: 1.2rem;
                    color: #E5432E;
                    font-weight: 600;
                }

                .product-num {
                  flex: 0 0 26%;

                    .num {
                        color: #E5432E;
                        font-weight: 600;
                    }
                }

                .product-money {
                  flex: 0 0 18%;

                    p {
                        &:nth-child(1) {
                            font-size: 1.2rem;
                            color: #E5432E;
                            font-weight: 600;
                        }
                    }
                }

              .product-info_img {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70px !important;
                position: relative;

                .product-info_giveaway {
                  position: absolute;
                  bottom: 0;
                  width: 70px;
                  background: rgba(0, 0, 0, 0.6);
                  color: #fff;
                  border-radius: 0 0 6px 6px;
                }
              }
            }
        }

        .list-show {

            .list-columns {
                padding: 0 10px;
                height: 48px;
                background: #F8F8F8;
                border: 1px solid #EDEDED;
                line-height: 48px;
                text-align: left;

                p {
                    width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:nth-child(1) {
                        width: 200px;

                    }

                    &:nth-child(2) {
                        width: 150px;
                    }

                    &:nth-child(3) {
                        width: 200px;
                    }
                }
            }

            .list-data {


                .list-data-row {
                    padding: 0 10px;
                    text-align: left;

                    &:nth-child(2n) {
                        background-color: #f2f2f2;
                    }

                    p {
                        width: 100px;

                        &:nth-child(1) {
                            width: 200px;

                        }

                        &:nth-child(2) {
                            width: 150px;
                        }

                        &:nth-child(3) {
                            width: 200px;
                        }
                    }

                    height: 40px;
                    line-height: 40px;
                    border-bottom:1px solid #EDEDED;
                }
            }
        }

    }

    .ticket {
        height: 70px;


        .tc {
            margin-left: 30px;

            .ck {
                margin: 0 15px;

                &.sel {
                    color: #3875F6;
                }
            }
        }
    }

    .bal {


        .bal-c {
            text-align: left;
            height: 30px;
        }

        .red {
            color: #E5432E;
            font-weight: 600;
        }
    }

    .payway {
        position: relative;

        .tip {
            display: block;
            position: absolute;
            left: 130px;
            top: 15px;
            border-radius: 5px;
            color: #ffffff;
            font-weight: normal;
            font-size: 0.875rem;
            padding: 0 0.75rem;
            line-height: 20px;
            background-color: #f45757;
            box-shadow: 0 1px 2px 1px rgb(0 1 0 / 20%);

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 100%;
                left: auto;
                border-top: 5px solid transparent;
                border-right: 5px solid #f45757;
                border-bottom: 5px solid transparent;
                z-index: 38;
                transform: translateY(-50%);
            }
        }

        .c {
            text-align: left;

        }

        .maxlimitAmt_box {
            text-align: left;
            padding: 5px 23px;

            span {
                background-color: #F45757;
                color: #ffffff;
                display: inline-block;
                padding: 5px;
                width: 15px;
                height: 15px;
                font-size: 12px;
                text-align: center;
                line-height: 5px;
                margin-right: 5px;
                border-radius: 50%;
            }

            label {
                color: #848484;
            }
        }
    }

    .delivery {
        .fee {
            color: #E5432E;
            font-weight: normal;
            font-size: 1rem;
        }
    }

    .invoice {


        .remark {
            margin-top: 20px;

            input {
                width: 1100px;
            }
        }

        .block-title {
            position: relative;

            p {
                &.link-color {
                    float: right;
                    color: #3875F6;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    .sum {
        color: #666;

        ul {
            li {
                margin: 20px 50px 20px 0;
                text-align: right;

                label {
                    display: inline-block;
                    width: 100px;
                }

                span {
                    display: inline-block;
                    width: 100px;
                    color: #E5432E;
                    font-size: 1rem;
                }

                &:nth-last-child(1) {
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }
        }
    }

    .btn {
        text-align: right;

        button {
            width: 140px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #E5432E;
            border-radius: 4px;
            font-size: 1.125rem;
            font-weight: 600;
            color: #E5432E;
            cursor: pointer;
        }
        button[disabled="disabled"] {
          background: #dbdbdb;
          cursor: no-drop;
        }
    }
}

.customer-list {
    .customer-input {
        margin: 5px 0;

        input {
            width: 90%;
        }

        button {
            border: 1px solid #dbdbdb;
            padding: 8px 15px;
            border-radius: 5px;
            background: #409eff;
            color: #fff;
        }
    }

    .customer-content {
        ul {
            li {
                padding: 0 10px;
                height: 40px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &:active {
                    background-color: #409eff;
                    color: #fff;
                }

                &:nth-child(2n+1) {
                    background-color: #EDEDED;

                    &:hover,
                    &:active {
                        background-color: #409eff;
                        color: #fff;
                    }
                }

                &.h {
                    height: 30px;
                    background-color: #EDEDED;
                    padding: 0 10px;
                }

                .code {
                    width: 100px;
                }

                .name {
                    width: 350px;
                }

                .addr {
                    width: 350px;
                }
            }
        }
    }
}

.arrears-dialog {
    font-size: 1rem;

    .proBox-msg {
        font-size: 1.1rem;

        p {
            text-align: center;
        }
    }

    .proBox-btn {
        text-align: center;
        margin-top: 30px;

        button {
            padding: 10px 30px;
            border: 1px solid #ededed;
            background-color: #2979ff;
            border-radius: 23px;
            color: #fff;
            font-size: 1.1rem;
            cursor: pointer;
        }
    }
}

.errorMsg {
    .errorMsg-list {
        background: #ebebeb;

        .errorMsg-list-box {
            min-height: 200px;
            max-height: 220px;
            padding: 0 5px 10px 5px;
            overflow: auto;

            .item-width {
                font-size: 14px;
                width: 20%;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background: #2979ff;
                color: #fff;
                border-right: 1px solid #ebebeb;
                padding: 5px 0;

                &.item-width-bg {
                    background-color: #fff;
                    color: #333;
                }
            }
        }
    }

    .errorMsg-bottom {
        .errorMsg-price {
            width: 100%;
            margin-top: 10px;
            color: #222;
            border-bottom: 1px solid #efefef;
            padding: 8px 0;

            p {
                font-size: 16px;
                font-weight: 700;
            }

            .errorMsg-price-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .errorMsg-btns {
            width: 100%;
            margin-top: 15px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 10px;
        }
    }
}
</style>

<script>
import { AccountStorage } from "@services/account";
import PageTop1 from "@/components-v2/layout/page-top1"
import PageTop from "@/components-v2/layout/page-top"
import PageBottom from "@/components-v2/layout/page-bottom"
import CheckBox from '@/components-v2/checkbox/index'
import Dialog from '@/components-v2/v2-dialog/index'
import { mapGetters, mapActions } from 'vuex';
const accountStorage = new AccountStorage();
let orderTimer;//订单进度定时器，
let timer;

export default {
    components: {
        PageTop, PageBottom, CheckBox, Dialog, PageTop1
    },
    computed: {
        ...mapGetters({
            actBal: 'actBal'
        }),
    },
    data() {
        return {
            lstWay: [], //配送方式
            lstAddress: [], //收货地址
            order: {}, //订单
            lstPayType: [], //支付方式
            lstticketTitle: [],//发票抬头列表
            showMoreBtn: true,//是否显示更多地址按钮
            showOrderList: false,//是否列表展示
            isInvoice: false,//是否开具发票
            addressId: null,//收货地址Id
            giveKey: null, //配送方式key
            payKey: null, // 支付类型，线下支付，线上支付
            checkCouponStatus: null,//是否参与优惠券
            isUseBalance: false,//是否用余额抵扣
            isUseCoupon: false,//优惠卷是否可以用
            invoiceForm: {
                //发票信息
                invoiceTitle: null, //发票抬头
                num: "", //纳税人识别号
                invoiceType: 1, //开票方式
                remarks: ""
            },
            uniqueToken: null,//唯一id
            userIdentity: accountStorage.getCacheUserInfo().channel, //用户身份（1普通用户  2供应商）
            // 客户信息
            userInfo: accountStorage.getCacheUserInfo(),
            isgivePhone: false,//是否需要电话开票服务
            disSubmit: false, //是否允许提交订单
            showCustomerDlg: false,//是否显示客户选择框
            userName: null,//查询客户
            userList: [], //客户列表
            userContent: {},
            showCreateOrderError: false,//创建订单异常
            showCreateOrderFail: false,//创建订单失败
            orderErrorMsg: null,
            errorData: {},//失败消息
            showDebt: false,//显示欠款提示
            arrearsData: {}, //欠款信息
            showProcess: false,//订单进度条
            orderPercent: 3,
            nums: 0,
            isComplete: false,
            HBdisabled: false,
        };
    },
    mounted() {
        this.uniqueToken = this.guid(20);
        if (this.userIdentity == "1") {
            //普通客户
            this.userInfo.userId = accountStorage.getCacheUserInfo().loginCompanyId;

        }
        this.getPreOrder().then(() => {
            // this.getTypeList();//支付类型
            this.deliveryAddress(); //收货地址
            this.getTickTitleList();//发票信息
        })
        this.distributionWay(); //配送方式
        this.getBal(); //余额
    },
    methods: {
        ...mapActions([
            'getActBal',
            'findCardList'
        ]),
        async getBal() {
            if (this.actBal.totalBalanceAmount == undefined) {
                this.getActBal();
            }
        },
        //配送方式
        async distributionWay() {
            const url = '/pjyy-deepexi-order-center/api/v1/order/OcOrder/distributionWay';
            let r = await this.$getHttpClient().get(url);
            if (r.data.code == '200') {
                this.lstWay = r.data.data;  //key,value形式
                if (!this.giveKey && this.lstWay.length > 0) {
                    this.lstWay[0].key;
                }
            }
        },

        //收货地址
        async deliveryAddress() {
            let params = {
              companyId: this.userInfo.userId,
              customer: this.userInfo.account
            }
            const url = '/pjyy-deepexi-user-center/api/v1/address/addressListNew' + `?companyId=${params.companyId}&customer=${params.customer}`;
            let res = await this.$getHttpClient().get(url, params);
            if (res.data.code == '200') {
              let arr = [];
              let { data } = res.data;
              let arr1 = data.filter(t => t.defaultStatus == 1);
              if (arr1.includes(1)) {
                data.forEach(t => {
                  if (t.defaultStatus == 1) {
                    this.addressId = t.id;
                    arr.push(t);
                  }
                })
                this.lstAddress = arr;
              } else {
                this.lstAddress = [data[0]]
                this.addressId = data[0].id;
              }
            }
        },
        //订单信息
        async getPreOrder() {
            const url = '/pjyy-deepexi-order-center/api/v1/order/OcOrder/findPreOrder/1?preuuid=' + this.$route.params.id;
            let r = await this.$getHttpClient().get(url);
            if (r.data.code == '200') {
                this.order = r.data.data || {};  //key,value形式
                if (r.data.data && r.data.data.addressInfoId) {
                    this.addressId = Number(r.data.data.addressInfoId);
                }

                // 支付方式
                if (r.data.data && r.data.data.payType) {
                    this.payKey = r.data.data.payType;
                }

                // 配送方式
                if (r.data.data && r.data.data.distributionWay) {
                    this.giveKey = r.data.data.distributionWay;
                }
                if (r.data.data && r.data.data.useRedPackets == 1) {
                    this.isUseBalance = true;
                }
                // 调取余额抵扣
                if (r.data.data && (r.data.data.balanceAmount <= 0 || r.data.data.isUseRedBag == '0')) {
                    this.HBdisabled = true
                }
                if (r.data.data) {
                  this.isUseCoupon = r.data.data.useCoupon == 1;//优惠券1使用 2不使用
                  this.invoiceForm.invoiceTitle = r.data.data.invoiceInfoId;
                  this.invoiceForm.remarks = r.data.data.invoiceRemark;
                  this.invoiceForm.num = r.data.data.invoice;
                  this.checkCouponStatus = r.data.data.checkCouponStatus;//是否参与优惠券0不可以参加1可以参加，为空没有优惠券
                }

                // 如果发票有信息，默认勾中
                if (this.invoiceForm.invoiceTitle) {
                    this.isInvoice = true;
                }

                //判断托运客户是否满足500
                if (r.data.data && r.data.data.customerDto != null) {
                    this.userInfo.name = r.data.data.customerDto.name;
                    this.userInfo.num = r.data.data.customerDto.account;
                    this.userInfo.address =
                        (r.data.data.customerDto.provinceName || "") +
                        (r.data.data.customerDto.cityName || "") +
                        (r.data.data.customerDto.districtName || "") +
                        (r.data.data.customerDto.detail || "");
                    this.userInfo.userId = r.data.data.customerDto.companyId;
                    this.isInvoice = false; //代客下单 不用 显示开发票信息
                }
            }
        },
        //支付类型
        async getTypeList() {
            const url = '/pjyy-deepexi-pay-center/api/v1/channel/validTypeList?companyId=' + this.userInfo.userId;
            let r = await this.$getHttpClient().get(url);
            let { code, data } = r.data;
            if (code == '200') {
                // 现金客户隐藏线下支付
                if (this.order.whetherCashCustomer && !this.order.consignCustomerAllowCash) {
                  this.lstPayType = data.filter(item => item.payTypeName !== '线下支付');
                } else {
                  // 非现金客户
                  this.lstPayType = data;  //key,value形式
                }
                if (!this.payKey) {
                    this.payKey = this.lstPayType[0].payType;
                }
            }
        },
        async clickPayType(index) {
            this.payKey = index;
            await this.savePreOrder();
        },
        //设置收获地址
        setAddr(item) {
            this.addressId = item.id;
            this.savePreOrder();
        },
        //重置发票信息
        setInvoice() {
            this.isInvoice = !this.isInvoice;
            this.invoiceForm.invoiceTitle = null;
            this.invoiceForm.num = '';
            this.invoiceForm.invoiceType = 1;
            this.invoiceForm.remarks = '';
        },
        checkBalanceBox() {
            //是否勾选红包
            this.isUseBalance = !this.isUseBalance;
            this.savePreOrder();
        },
        // 选择配送方式
        async clickGiveType(key) {
            this.giveKey = key;
            await this.savePreOrder();
        },
        //获取发票抬头列表
        async getTickTitleList() {
            const url = "/pjyy-deepexi-user-center/api/v1/invoice/invoiceList?companyId=" + this.userInfo.userId;
            this.$getHttpClient()
                .get(url)
                .then(res => {
                    this.lstticketTitle = res.data.data;
                });
        },
        // 发票抬头事件
        async getInvoiceTitle() {
            this.lstticketTitle.forEach(item => {
                if (item.id === this.invoiceForm.invoiceTitle) {
                    this.invoiceForm.num = item.taxNumber;
                }
            });
            await this.savePreOrder();
        },
        //提示优惠卷过期
        isCouponChange() {
            this.isUseCoupon = !this.isUseCoupon;
            if (this.isUseCoupon) {
                this.savePreOrder();
            } else {
                this.$messageTip("您有" + this.order.couponCurrentDeductionPrice + "元优惠券将在" + this.order.couponEndTime + "后过期", 'warning');
            }
        },
        //获取活动提示
        getPayActTip() {
            let tip = "";
            if (this.order.onlinePaymentPercentage != null) {
                let percent = this.order.onlinePaymentPercentage;
                tip = `活动期间，线上支付立减${percent}‰`;
            }
            if (this.order.whetherCashCustomer) {
                if (tip.length > 0) {
                    tip += ",";
                }
                //现金客户
                tip += "*线上支付：完成支付后再发货。";
            }
            return tip;
        },
        // 收货地址，支付方式，配送方式，发票信息存缓存
        async savePreOrder() {
            let data = {
                addressInfoId: this.addressId,
                invoiceInfoId: this.invoiceForm.invoiceTitle,
                invoiceRemark: this.invoiceForm.remarks,
                invoice: this.invoiceForm.num,
                payType: this.payKey,
                totalMoney: this.order.allTotal,
                distributionWay: this.giveKey,
                freight: this.order.freight,
                preuuid: this.$route.params.id,
                useCoupon: this.isUseCoupon ? 1 : 2,
            };
            //是否余额抵扣
            data.useRedPackets = this.isUseBalance ? 1 : 2;

            let url = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/savePreOrder";
            const result = await this.$getHttpClient().post(url, data);
            if (result.data.code === "200") {
                this.getPreOrder();
                return result.data.data;
            }
        },
        //无视异常继续提交
        enterOrder(id) {
            const URL = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/confirmSubmission/" + id;
            this.$getHttpClient({ useSpinner: true })
                .post(URL)
                .then(res => {
                    this.showProcess = false;
                    this.toPayment(res.data.data.orderNumber)
                });
        },
        toPayment(orderId) {
          this.findCardList().then(() => {
            this.$router.replace({
              name: "v2-payment",
              query: {
                orderId: orderId,
                type: "order"
              }
            });
          })
        },
        // to支付
        toPay() {
            if (timer) {
                clearInterval(timer);
            }
            this.disSubmit = true;
            // 处理收货地址参数
            let addressObj = {};
            this.lstAddress.forEach(item => {
                if (this.addressId === item.id) {
                    addressObj.userName = item.consignee;
                    addressObj.phoneNumber = item.consigneePhone;
                    addressObj.province = item.province;
                    addressObj.provinceName = item.provinceName;
                    addressObj.distributionAddr = item.provinceName + item.cityName + item.districtName + item.addreDetail;
                }
            });
            // 获取发票抬头名称
            let invoiceObj = {
                invoiceIdentify: this.invoiceForm.num,
                invoiceType: this.invoiceForm.invoiceType,
                invoiceRemark: this.invoiceForm.remarks
            };
            if (this.lstticketTitle) {
              this.lstticketTitle.forEach(item => {
                if (item.id === this.invoiceForm.invoiceTitle) {
                  invoiceObj.invoiceName = item.title;
                }
              });
            }
            if (!this.isInvoice) {
                invoiceObj = {};
            }
            // 接口入参
            let _this = this;
            let data = {
                ...addressObj,
                uniqueToken: this.uniqueToken,
                payType: this.payKey,
                distributionWay: this.giveKey,
                invoice: this.isInvoice ? 1 : 2,
                ...invoiceObj,
                orderWay: 1,
                supplierId: this.userIdentity, //用户身份（1普通用户，2供应商）
                deliveryId: this.userInfo.userId,
                deliverName: this.userInfo.name,
                balanceAmount: this.order.balanceAmount,
                checkBalance: this.isUseBalance,
                checkUseCoupon: this.isUseCoupon,//是否使用优惠券
                couponId:this.order.couponId,//优惠券id
                couponType:this.order.couponType,//优惠券类型
                givePhone: this.isgivePhone ? 1 : 0
            };

            if (this.actBal.totalBalanceAmount > 0 && !this.HBdisabled) {
                if (!this.isUseBalance) {
                    let content = `本次订单可用抵扣余额：${this.order.balanceAmount}元，是否使用？`;
                    this.$confirm(content, '提示', {
                        confirmButtonText: '使用',
                        cancelButtonText: '不使用',
                    }).then((() => {
                        _this.isUseBalance = true;
                        data.checkBalance = true;
                        _this.paymoneey(data)
                    }), (() => {
                      _this.paymoneey(data)
                    }));
                } else {
                  _this.paymoneey(data)
                }
            } else {
                _this.paymoneey(data)
            }
        },
        paymoneey(data) {
            const saveOrder = "/pjyy-deepexi-order-center/api/v2/order/OcOrder/saveOrder";
            this.$getHttpClient({ useSpinner: false, timeout: 1500000 }).post(saveOrder, data)
                .then(res => {
                    clearInterval(orderTimer);
                    console.log("返回成功清除定时器");
                    if (res.data.code == '500') {
                        this.$messageTip(res.data.msg || '系统异常', 'error');
                    }
                    if (res.data.code == "200") {
                        if (res.data.data) {
                            // tip  是否提示
                            if (res.data.data.tip) {
                                this.disSubmit = false;
                                this.$confirm(res.data.msg, '提示').then(() => {
                                    this.toPay()
                                })
                                return;
                            }
                        }
                        if (typeof res.data.payload != 'undefined') {
                            if (res.data.payload.statusCode === 1) {
                                this.showCreateOrderFail = true;
                                this.errorData = res.data.payload;

                            } else if (res.data.payload.statusCode === 2) {

                                this.showCreateOrderError = true;
                                this.orderErrorMsg = res.data.payload.message;

                            } else if (res.data.payload.statusCode === 3) {
                                //带客户下单错误
                                this.$confirm('尊敬的普健用户您好：当前客户为现金托运客户,支付金额需满足299元，请返回购物车加购或变更其他客户进行下单。', '温馨提示', {
                                    confirmButtonText: '变更客户',
                                    cancelButtonText: '返回购物车',
                                }).then(() => {
                                    this.userContent = {};
                                }, () => {
                                  this.$router.push({name: 'v2-shopcard'})
                                });

                            } else if (res.data.payload.statusCode === 4) {
                                this.$confirm('尊敬的普健用户您好：当前客户为现金托运客户,由于价格或库存变动，支付金额需满足299元，请返回购物车加购或变更其他客户进行下单。', '温馨提示', {
                                    confirmButtonText: '变更客户',
                                    cancelButtonText: '返回购物车',
                                }).then(() => {
                                    this.userContent = {};
                                }, () => {
                                  this.$router.push({name: 'v2-shopcard'})
                                });
                            } else if (res.data.payload.statusCode === 5) {
                                this.arrearsData = res.data.payload;
                                this.showDebt = true;
                            }
                        }

                        if (!res.data.data && typeof res.data.payload == 'undefined') {
                            this.$messageTip(res.data.msg, 'error');
                        }
                        if (res.data.data) {
                          this.toPayment(res.data.data.orderNumber)
                        }
                    }
                    if (res.data.code != "200") {
                        this.$messageTip(res.data.msg || '系统异常', 'error');
                        let th = this;
                        th.nums = 0;
                        th.orderPercent = 3;
                        timer = setInterval(() => {
                            th.disSubmit = false;
                        }, 5000);
                    }
                }).catch(() => {
                    let th = this;
                    timer = setInterval(() => {
                        th.disSubmit = false;
                    }, 5000);
                });
          orderTimer = setInterval(() => {
            this.getOrderProgress();
          }, 1000);
        },
        //创建订单进度条
        async getOrderProgress() {
            this.showProcess = true;
            let requestTime = 60 * 25; // 25分钟
            // 查询创建订单进度
            const orderProgress = "/pjyy-deepexi-order-center/api/v1/order/OcOrder/orderProgress";
            this.nums = this.nums + 1;
            if (this.nums === requestTime) {
                this.isComplete = true
            }
            if (this.nums > requestTime) {
                return;
            }
            const response = await this.$getHttpClient({ useSpinner: false, timeout: 1500000 }).get(orderProgress + "?uuid=" + this.uniqueToken + "&isComplete=" + this.isComplete + "&num=" + this.nums);
            let { code, data } = response.data;
            if (code === "200") {
                // type类型 1、进度 2、正常订单信息  3、异常信息 4.整单无库存
                if (data.type === 1) {
                    this.orderPercent = parseInt(data.progress);
                    if (data.progress === -1) {
                        this.$messageTip("下单失败");
                        this.uniqueToken = this.guid(20);
                        this.orderPercent = 3;
                        this.orderProgressCallback()
                        console.log("type=1清除定时器")
                    }
                } else if (data.type === 2) {
                    this.orderProgressCallback()
                    console.log("type=2清除定时器")
                    this.toPayment(data.ocOrderPlaceVO.orderNumber)
                } else if (data.type === 3) {
                    this.orderProgressCallback()
                    console.log("type=3清除定时器,进度条有JSON对象的异常")
                    this.showCreateOrderFail = true;
                    this.errorData = data.abnormalOrderLineForm;
                } else if (data.type === 4) {
                    this.orderProgressCallback()
                    this.orderErrorMsg = data.msg;
                    this.showCreateOrderError = true;
                    console.log("type=4清除定时器,进度条有JSON对象的异常")
                }
            }
        },
        orderProgressCallback() {
          this.nums = 0;
          this.isComplete = false;
          this.showProcess = false;
          clearInterval(orderTimer);
        },
        //立即还款
        async nowArrears() {
            let account = accountStorage.getCacheUserInfo().account;
            const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/orderBill/getOrdersAndLike/${account}`;
            const result = await this.$getHttpClient().get(URL);
            if (result.data.code == 200) {
                window.open(result.data.data)
            }
        },
        getCustomerList() {
            // 获取代客下单的客户
            const getInfoList = "/pjyy-deepexi-user-center/api/v1/company/getInfoList";
            let data = {
                accountOrName: this.userName,
                page: 1,
                pageSize: 20
            };
            this.$getHttpClient()
                .post(getInfoList, data)
                .then(res => {
                    res.data.data.list.forEach(item => {
                        item.checked = false;
                    });
                    this.userList = res.data.data.list;
                    if (this.userList.length > 10) {
                        this.userList = this.userList.slice(0, 10);
                    }
                    this.disSubmit = false;
                });
        },
        setUserInfo(item) {
            this.userContent = item;
        },
        //确定选择的客户
        sureSelect() {
            let val = this.userContent;
            if (!this.userContent.name) {
                this.$messageTip('请选择客户', 'error');
            } else {
                let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/findPreOrderShippingCustomers`;
                this.$getHttpClient()
                    .post(url, {
                        companyId: val.companyId,
                        name: val.name,
                        account: val.account,
                        uuid: this.$route.params.id,
                        provinceName: val.provinceName,
                        cityName: val.cityName,
                        districtName: val.districtName,
                        detail: val.detail,
                    })
                    .then(res => {
                        if (res.data.code == 200) {
                            if (res.data.data) {
                                this.userInfo.name = val.name;
                                this.userInfo.num = val.account;
                                this.userInfo.address =
                                    (val.provinceName || "") +
                                    (val.cityName || "") +
                                    (val.districtName || "") +
                                    (val.detail || "");
                                this.userInfo.userId = val.companyId;
                                this.isInvoice = false;

                                this.getPreOrder().then(() => {
                                    this.getTypeList();//支付类型
                                    this.deliveryAddress(); //收货地址
                                    this.getTickTitleList();//发票信息
                                })
                                this.showCustomerDlg = false;
                            } else {
                                this.$confirm('尊敬的普健用户您好：当前客户为现金托运客户,支付金额需满足299元，请返回购物车加购或变更其他客户进行下单。', '温馨提示', {
                                    confirmButtonText: '变更客户',
                                    cancelButtonText: '返回购物车',
                                }).then(() => {
                                    this.userContent = {};
                                }, () => {
                                  this.$router.push({ name: 'v2-shopcard' })
                                });
                            }
                        }
                    });
            }
        },
        //生成唯一标识
        guid(len = 32, firstU = true, radix = null) {
            let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            let uuid = [];
            radix = radix || chars.length;

            if (len) {
                // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
                for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                let r;
                // rfc4122标准要求返回的uuid中,某些位为固定的字符
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';

                for (let i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }
            // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
            if (firstU) {
                uuid.shift();
                return 'u' + uuid.join('');
            } else {
                return uuid.join('');
            }
        },
        jumpTo(name) {
            this.$router.push({ name: name });
        }
    }
}
</script>
